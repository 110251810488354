import React from "react";
//import Social from "../../components/Social";

const achievementsContent = [
  { title: "Penetration Testing", subTitle1: "Web, Mobile, API &", subTitle2: "Infrastructure" , icon: "fa-shield" },
  { title: "Code Review", subTitle1: "Source Code", subTitle2: "analysis", icon:"fa-align-left"},
  { title: "Development", subTitle1: "Front-end & Back-end", subTitle2: "Mobile", icon:"fa-code fa-duotone" },
  { title: "Individual Training", subTitle1: "Penetration Testing", subTitle2: "Prep. for OSCP or any beginner", icon:"fa-user" },
  { title: "Awareness Training", subTitle1: "For Organizations", subTitle2: "and communities", icon: "fa-users" },
];

const Achievements = () => {
  return (
    <div className="row">
      {achievementsContent.map((val, i) => (
        <div className="col-6" key={i}>
          <div className="box-stats with-margin">
            <i className={"fa service-icon position-relative "+val.icon}></i>
            <h3 className="poppins-font position-relative">{val.title}</h3>
            <p className="open-sans-font m-0 position-relative text-uppercase">
              {val.subTitle1} <span className="d-block">{val.subTitle2}</span>
            </p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default Achievements;
