import React from "react";
import { Tab, Tabs, TabList, TabPanel } from "react-tabs";
import Content from "../portfolio/content_item";
const Portfolio = () => {
  
  return (
    <Tabs>
      <TabList className="portfolio-tab-list">
        <Tab>A little bit of everything</Tab>
      </TabList>

      <div className="portfolio-tab-content">
        <TabPanel>
          <ul className="row grid justify-content-center">
            
          {Content("GitBook","gitbook.png","https://book.cybersec.az")}
          {Content("Youtube","youtube.png","https://www.youtube.com/user/aliyevchannel")}
          {Content("Medium",'medium.png',"https://medium.com/@rabilali")}
          
          </ul>
        </TabPanel>
        {/* End MOCKUP Project */}
      </div>
    </Tabs>
  );
};

export default Portfolio;
