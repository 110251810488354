import React from "react";
import Achievements from "./Achievements";
/*
import Education from "./Education";
import Experience from "./Experience";
import PersonalInfo from "./PersonalInfo";
import Skills from "./Skills";
*/
const index = () => {
  return (
    <section className="main-content ">
      <div className="container">
        <div className="row">
          
          {/*  Boxes Starts */}
          <div className="col-xl-12 col-lg-7 col-12 mt-5 mt-lg-0">
            <Achievements />
          </div>
          {/* Achievements Ends */}
        </div>
        {/* End .row */}


        {/* Skills 
        <hr className="separator" />
        <div className="row">
          <div className="col-12">
            <h3 className="text-uppercase pb-4 pb-sm-5 mb-3 mb-sm-0 text-left text-sm-center custom-title ft-wt-600">
              My Skills
            </h3>
          </div>
          <Skills />
        </div>
        */}

        <hr className="separator mt-1" />

        {/* Experience & Education Starts 
        <div className="row">
          <div className="col-12">
            <h3 className="text-uppercase pb-5 mb-0 text-left text-sm-center custom-title ft-wt-600">
              Want to book a meeting?
            </h3>
          </div>
          <div className="col-lg-12 m-15px-tb">
            <div className="resume-box">
              <Experience />
            </div>
          </div>
        </div>
        {/*  Experience & Education Ends */}
      </div>
    </section>
  );
};

export default index;
