import React from "react";

const Address = () => {
  return (
    <>
      
      {/* End .custom-span-contact*/}

      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-envelope-square position-absolute"></i>
        <span className="d-block">mail me</span>{" "}
        <a target="_blank" href="mailto:rabil.aliyev@yandex.az">rabil@cybersec.az</a>
      </p>
      

      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-facebook-square position-absolute"></i>
        <span className="d-block">Facebook</span>{" "}
        <a target="_blank" href="https://www.facebook.com/rabil.ali24/" >Rabil Aliyev</a>
      </p>
      

      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-linkedin-square position-absolute"></i>
        <span className="d-block">Linkedin</span>{" "}
        <a target="_blank" href="https://www.linkedin.com/in/rabil-aliyev/">Rabil Aliyev</a>
      </p>

      <p className="open-sans-font custom-span-contact position-relative">
        <i className="fa fa-phone-square position-absolute"></i>
        <span className="d-block">Mobile</span>{" "}
        <a target="_blank" href="tel:+994504441337">050 444 1337</a>
      </p>
       
    </>
  );
};

export default Address;
