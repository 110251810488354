import React from "react";
//import Modal from "react-modal";
//import ModalOneContent from "./modal/ModalOneContent";

export default function Portfolio(name,bg_img,url) {
  //const [isOpen, setIsOpen] = useState(false);

  function onClickListener() {
    window.open(url, '_blank');
  }

  return (
    <li
              data-aos="fade-right"
              data-aos-duration="1200"
              data-aos-delay="0"
            >
              <figure onClick={onClickListener}
              >
                <img src={"img/blog/"+bg_img} alt={name} />
                <div>
                    <span className="content-title">{name}</span>
                </div>
              </figure>
            </li>
  );
};

